import { Button, ButtonSubmit, Checkbox, Modal } from '@flipgrid/flipkit';
import { useFetcher, useMatches, useNavigate } from '@remix-run/react';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import handleIds from '~/constants/handleIds';
import routes from '~/constants/routes';
import { logEvent } from '~/helper/helper';
import useGetUser from '~/hooks/useGetUser';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { RouteType, RouteTyping, Topic } from 'types';

type Props = {
  topic: Topic;
  onRequestClose: OnRequestCloseType;
  redirectsTo?: RouteType;
};

const DeleteTopicModal = ({ topic, onRequestClose, redirectsTo }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher<Response>();
  const [checked, setChecked] = useState(false);
  const matches = useMatches();
  const navigate = useNavigate();
  const user = useGetUser();

  const group = (matches.find(m => m.handle?.id === handleIds.Group)?.data as RouteTyping<'Group'>)?.group?.data;
  const groupId = matches.find(route => route.handle?.id === handleIds.Group)?.params.groupid ?? '';

  const [loggedTelemetry, setLoggedTelemetry] = useState(false);

  const onModalClose = useCallback(() => {
    onRequestClose(t('shared.deleteModalClosed'));
  }, [onRequestClose, t]);

  useEffect(() => {
    if (fetcher.type === 'done') {
      const { data } = fetcher;

      if (data.ok) {
        if (!loggedTelemetry) {
          logEvent(
            { name: 'topic_deleted' },
            {
              grid_id: groupId,
              access_control: group?.access_control || undefined,
              topic_id: topic.id,
              user_id: user.id,
              member_id: group?.membership?.id || undefined,
              role: group?.membership?.role || undefined,
              screen: 'TopicView',
            },
          );
          setLoggedTelemetry(true);
        }
        onRequestClose(t('deleteTopicModal.topicWasDeletedModalClosed'));
        if (redirectsTo) navigate(redirectsTo);
      }
      onModalClose();
    }
  }, [
    fetcher,
    fetcher.type,
    group?.access_control,
    group?.membership?.id,
    group?.membership?.role,
    groupId,
    loggedTelemetry,
    navigate,
    onModalClose,
    onRequestClose,
    redirectsTo,
    t,
    topic.id,
    user.id,
  ]);

  return (
    <Modal show onClose={onModalClose}>
      <fetcher.Form method="post" action={routes.GROUPS_ID_TOPICS_FUNC(groupId)}>
        <h1 className="fk-modalHeader">{t('shared.deleteTopic')}</h1>
        <div className="fk-modalBody">
          <p>
            <Trans i18nKey="deleteTopicModal.confirmDeleteTopic" values={{ count: topic.response_count }}>
              By deleting {{ topicTitle: topic.title }} you will also permanently delete
              {{ responseCount: topic.response_count }} video. This action cannot be undone.
            </Trans>
          </p>
          <Checkbox
            wrapperClassName="mt1"
            data-testid="deleteTopicModal__checkbox__confirmDelete"
            checked={checked}
            onChange={() => setChecked(prev => !prev)}
          >
            <p className="fk-m0">{t('deleteTopicModal.wantToDeleteTopic')}</p>
          </Checkbox>
        </div>
        <Modal.Actions className="mt2">
          <Button theme="secondary" data-testid="deleteTopicModal__button__cancel" onClick={onModalClose}>
            {t('common.cancel')}
          </Button>
          <ButtonSubmit
            name="_action"
            value="deleteTopic"
            theme="danger"
            type="submit"
            data-testid="deleteTopicModal__button__removeOrDelete"
            loading={fetcher.state !== 'idle'}
            disabled={!checked}
          >
            {t('common.delete')}
          </ButtonSubmit>
        </Modal.Actions>
        <input type="hidden" name="groupId" value={groupId} />
        <input type="hidden" name="topicId" value={topic.id} />
      </fetcher.Form>
    </Modal>
  );
};

export default DeleteTopicModal;
